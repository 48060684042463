[
  {
    "Question": "Was passiert bei schlechtem Wetter?",
    "Answer": "Das Festival findet bei jedem Wetter statt. Es gibt ausreichend Unterstellmöglichkeiten - auch mit Barbetrieb!"
  },
  {
    "Question": "Gibt es einen Zeltplatz?",
    "Answer": "Es gibt keinen Zeltplatz. Mit Wohnmobilen/Wohnwagen ist das Campen auf dem Parkplatz \"Veste-Bick\" auf den ausgeschriebenen Stellplätzen möglich. "
  },
  {
    "Question": "Darf ich mein Haustier mit zum Rodeo bringen?",
    "Answer": "Das Mitbringen von Hunden, Katzen und anderen Haustieren ist nicht gestattet. Danke für euer Verständnis."
  },
  {
    "Question": "Wie finde ich die Ansprechpartner*innen vom Awareness-Team?",
    "Answer": "Das Awareness-Team ist erkennbar an lilafarbenen Westen. Gerne helfen dir auch alle anderen Rodeo-Mitarbeiter*innen eine*n Ansprechpartner*in zu finden."
  },
  {
    "Question": "Kann ich auf dem Gelände mit Karte zahlen?",
    "Answer": "Nein, es ist nur Barzahlung möglich."
  },
  {
    "Question": "Ist der Eintritt für Begleitpersonen mit Schwerbehindertenausweis frei?",
    "Answer": "Menschen mit Schwerbehinderten-Ausweis und einem GdB von mindestens 50% bekommen freien Eintritt. Bei einem Merkzeichen B im Ausweis bekommt die Begleitperson ebenfalls freien Eintritt."
  }
]