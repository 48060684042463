[
  {
    "Sponsor": "Verpa Folie",
    "Logo": "verpa.jpg",
    "URL": "https://verpa.de/"
  },
  {
    "Sponsor": "Radio Eins",
    "Logo": "radioeins.jpg",
    "URL": "https://www.radioeins.com/"
  },
  {
    "Sponsor": "Werbeagentur Strobel",
    "Logo": "strobel.jpg",
    "URL": "https://www.werbeagentur-strobel.de"
  },
  {
    "Sponsor": "Innovationsfonds Kultur der Coburger Wirtschaft",
    "Logo": "innovationsfonds.jpg",
    "URL": "https://www.coburg.de/innovationsfonds-kultur/"
  },
  {
    "Sponsor": "Finanzmakler Kempinski",
    "Logo": "kempinski.jpg",
    "URL": "https://finanzmakler-kempinski.de/"
  },
  {
    "Sponsor": "Lions Club Coburg",
    "Logo": "lionsclub.jpg",
    "URL": "https://www.lions-coburg.de"
  },
  {
    "Sponsor": "ASCO Sprachenschule Coburg",
    "Logo": "asco.jpg",
    "URL": "https://asco-coburg.de/"
  },
  {
    "Sponsor": "Sparkasse Coburg-Lichtenfels",
    "Logo": "sparkasse.jpg",
    "URL": "https://www.sparkasse-co-lif.de/"
  },
  {
    "Sponsor": "Kontec GmbH",
    "Logo": "kontec.jpg",
    "URL": "https://kontec.net/"
  },
  {
    "Sponsor": "R.I.O.! Rock in Oberfranken",
    "Logo": "riosamuel.jpg",
    "URL": "https://www.bezirk-oberfranken.de/rio"
  },
  {
    "Sponsor": "Lotto Wöhner",
    "Logo": "lottowoehner.jpg",
    "URL": "https://lotto-woehner.de/"
  },
  {
    "Sponsor": "Picknick Coffee&Snacks",
    "Logo": "picknick.jpg",
    "URL": "https://www.facebook.com/picknick.co/"
  },
  {
    "Sponsor": "Malerfachbetrieb Dirk Fröhlich",
    "Logo": "dirkfroehlich.jpg",
    "URL": "https://www.malerfachbetrieb-froehlich.de/"
  },
  {
    "Sponsor": "Taxi Bauersachs",
    "Logo": "bauersachs.jpg"
  },
  {
    "Sponsor": "Die Reisepalette",
    "Logo": "reisepalette.jpg",
    "URL": "https://www.reisepalette.de/"
  },
  {
    "Sponsor": "Glaserei Späth",
    "Logo": "glasereispaeth.jpg",
    "URL": "https://www.glaserei-spaeth.de/"
  },
  {
    "Sponsor": "WiFöG",
    "Logo": "wifoeg.jpg",
    "URL": "https://www.coburg.de/microsite/wirtschaftsfoerderung/"
  },
  {
    "Sponsor": "Eventie Dekoration - Papeterie und Gastro",
    "Logo": "eventie.jpg",
    "URL": "https://eventie.de/"
  },
  {
    "Sponsor": "W.u.K. Baumann KG",
    "Logo": "baumann.jpg",
    "URL": "https://biowaerme-baumann.de/"
  },
  {
    "Sponsor": "Buchhandlung Riemann",
    "Logo": "riemann.jpg",
    "URL": "https://shop.riemann.de/shop/"
  },
  {
    "Sponsor": "Schunk Präzisionswerkzeuge",
    "Logo": "schunk.jpg",
    "URL": "https://www.schunk-werkzeuge.de/"
  },
  {
    "Sponsor": "haxenbraterei"
  },
  {
    "Sponsor": "Alfred Jahn",
    "Logo": "",
    "URL": "www.pianoteile.com"
  }
]