[
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "in den Pausen",
    "Name": "Zaubershow mit Blume",
    "Genre": "Zauberei",
    "City": "Coburg",
    "Country": "DE"
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "14:00 Uhr",
    "Name": "Falsetrip",
    "Genre": "Hardcore",
    "City": "Ebern/Coburg",
    "Country": "DE",
    "Info": "Die famosen Falsetrip aus Ebern, der Heimat unseres Präsis, werden den Samstag auf der Hauptbühne eröffnen. Ein bisschen Hardcore, eine Rohscheibe Punk und diverse Trash- und Black Metal- Einflüsse mit Feinschliff im Herzen Coburgs: This is Ebern, not L.A.! "
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "in den Pausen",
    "Name": "OBO Band",
    "Genre": "Mod.SchlagerMetalPunk",
    "City": "Coburg",
    "Country": "DE",
    "Info": "Sie gehören zum Outside Rodeo wie der Bühnen-Büffelkopf und kaltes Bier: Unsere Buddys von der OBO Band. Sie sind laut, bunt und einzigartig. Wir freuen uns wie jedes Jahr auf ihre Hits 2024 auf der Bühne am Gleis."
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "14:55 Uhr",
    "Name": "Girls Go Ska",
    "Genre": "Ska/Punk",
    "City": "Mexico",
    "Country": "MX",
    "Info": "Sie sind zurück. Die Überraschung des Nach-Corona-Rodeo auf der Ernstfarm. Und dieses Mal hochverdient auf der Hauptbühne. Famoser, traditioneller All-girl-Ska aus Mexico City. Ihr Name ist Programm. Abrocken bis der Arzt kommt."
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "16:05 Uhr",
    "Name": "Gum Bleed",
    "Genre": "Streetpunk",
    "City": "Beijing",
    "Country": "CN",
    "Info": "Die \"The Casualties\" Asiens? Überzeugt euch selbst. Nach \"Rowling Bowling\" ist mal wieder eine Band aus der überraschend stabilen Punk-Szene Chinas am Alten Güterbahnhof. Als 15-jährige in Beijing von einem \"Sex Pistols\" Tape und \"The Clash\" inspiriert, werden sie euch mit ihrem Hardcore und Street-Punk begeistern."
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "17:20 Uhr",
    "Name": "Dee Cracks",
    "Genre": "Punkrock",
    "City": "Wien",
    "Country": "AT",
    "Info": "Seit 2003 mit über 800 Konzerten weltweit dabei und jetzt endlich bei uns am Alten Güterbahnhof: Österreichs Punkrock Aushängeschild Deecracks mit ihrer Vision von Rock'n Roll. Wir freuen uns wie Bolle, Cheers!"
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "in den Pausen",
    "Name": "Rumkicks",
    "Genre": "Punk-Rock",
    "City": "Seoul",
    "Country": "KR",
    "Info": "Last but not least haben wir euch bei der Bandpräsentation noch einen Special Guest aufgehoben: Aus der Hauptstadt Südkoreas, Seoul, kommen Rumkicks an den Alten Güterbahnhof! Wir freuen uns auf All-Girl-Gute Laune-Highspeed-Punkrock zwischen Toy Dolls und Bad Cop/Bad Cop! Punk ist definitely not dead! "
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "18:30 Uhr",
    "Name": "Snuff",
    "Genre": "Melodic-Punk",
    "City": "London",
    "Country": "GB",
    "Info": "Im dritten Anlauf haben wir es geschafft: Die Melodic-Punk-Giganten aus London beehren uns mit voller Kapelle und ihrer bitter-süßen Musik. Mächtige \"Leatherface\"-Gitarren, dazu Orgel eine fetzige Posaune und an den Drums Szene-Urgestein \"Duncan Redmond\", der sich die Kehle aus dem Leib singt. Wir sehen uns vor der Bühne. "
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "20:00 Uhr",
    "Name": "Frittenbude",
    "Genre": "Rap/Punkrock",
    "City": "Berlin",
    "Country": "DE",
    "Info": "Let's go Outside Rodeo 2024. Wir starten mit den genialen Jungs von Frittenbude aus Berlin. Ihr Mix aus Elektro, Punk und HipHop ist sowohl Einladung für den Rodeo-Dancefloor, als auch Futter für das Gehirn. Wir führen die Freiheit der Welt und Straßen aus Zucker direkt zum Alten Güterbahnhof."
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "in den Pausen",
    "Name": "Acker Dolls",
    "Genre": "Punk/Hiphop",
    "City": "Coburg",
    "Country": "DE",
    "Info": "Wir freuen uns auf eines der interessantesten Projekte der Coburger Punk-Szene am Alten Güterbahnhof. Die Acker Dolls von Ex-\"D.R.E.C.K\" und \"Plastikschmidt\" Frontman Völki. Es gibt live äußerst raren, großartigen Punk mit HipHop Sänger, tiefgründigen Texten und sympathischem Understatement auf die Ohren. Völki hört die Signale und seinen Acker Dolls ist nichts egal. "
  },
  {
    "Year": "2024",
    "Day": "Samstag",
    "Date": "20.07.2024",
    "Time": "21:30 Uhr",
    "Name": "The Locos",
    "Genre": "Ska-Punk",
    "City": "Madrid",
    "Country": "ES",
    "Info": "Ska ist auf dem Outside Rodeo essentiell. Und wenn dieser von Ex-\"Ska-P\" Shouter Pipi und seinen Jungs aus Madrid kommt, wird es legendär. The Locos, die Verrückten, werden euch mit ihrem politisch-rebellischen Ska-Punk ordentlich einheizen. Resistire!"
  }
]