[
  {
    "Year": "2024",
    "Day": "Freitag",
    "Date": "19.07.2024",
    "Time": "in den Pausen",
    "Name": "Homestudio",
    "Genre": "Punk/Rock",
    "City": "Coburg",
    "Country": "DE",
    "Info": "Wir wollten die Jungs unbedingt und wir haben sie bekommen. Aber lasst sie sich selbst vorstellen: \"Ey Digger, Homestudio ist safe die jüngste Band auf eurem Alten-Knacker-Festival. Wir sind Johannes, Ferdinand, Elias und Johann. Uns gibt’s jetzt schon seit drei Jahren, mittlerweile sind wir alle so um die 14, also immer noch total fresh. Wir hauen euch ein paar mies geile Klassiker um die Ohren, die geschrieben wurden, als wir noch in die Windeln geschissen haben. Kennt ihr bestimmt was davon. Also nicht cringe rumstehen, sondern ordentlich die alten Knochen schütteln. Seh`n uns am Bahnsteig.\" "
  },
  {
    "Year": "2024",
    "Day": "Freitag",
    "Date": "19.07.2024",
    "Time": "17:00 Uhr",
    "Name": "If We Last",
    "Genre": "Pop-Punk",
    "City": "Kulmbach",
    "Country": "DE",
    "Info": "Am Rodeo Freitag werden If We Last aus Kulmbach ein Pop-Punk-Feuerwerk zünden. Wir freuen uns mit euch auf eine Mischung aus Herzschmerz-Liebesstories und Eskalation im Moshpit. Die Achterbahn der Liebe, die Kritik an den von der Gesellschaft vorgelebten Strukturen und eine Feel-Good-Time für alle."
  },
  {
    "Year": "2024",
    "Day": "Freitag",
    "Date": "19.07.2024",
    "Time": "17:50 Uhr",
    "Name": "Todeskommando Atomsturm",
    "Genre": "Punkrock",
    "City": "München",
    "Country": "DE",
    "Info": "Wir sind sehr stolz euch die Fünf aus München wieder einmal in Coburg präsentieren zu können. Sicherlich einer der spannendsten deutschsprachigen Punk Acts der Jetztzeit. Musikalisch wie textlich auf den Punkt, melodisch, ausgefeilt, düster und wütend: Todeskommando Atomsturm!"
  },
  {
    "Year": "2024",
    "Day": "Freitag",
    "Date": "19.07.2024",
    "Time": "18:50 Uhr",
    "Name": "TYNA",
    "Genre": "Punkrock",
    "City": "Hamburg",
    "Country": "DE",
    "Info": "Dieses Jahr hat es uns mal wieder erwischt: The Meffs haben ihre Show beim Outside Rodeo gecancelt. Schade, aber wir haben einen mehr als respektablen Ersatz für Euch gebucht: TYNA! Die Hamburger Band TYNA ist ein wilder Haufen unverbesserlicher Sturköpfe; NDW-Synthies flirten  mit brätzeligen Punk-Gitarren, Gangshouts und Pop- Hooks. Texte mit Rückgrat behandeln Themen wie  Rassismus, mentale Gesundheit und Empowerment. Live und auf Platte klatschen dich TYNA mit viel Energie und zeitgeistigem, eigenständigem Sound an die Wand. Mit geballter Power laden TYNA ein, gemeinsam am Outside Rodeo zu feiern und zu kämpfen, für dich und für andere."
  },
  {
    "Year": "2024",
    "Day": "Freitag",
    "Date": "19.07.2024",
    "Time": "in den Pausen",
    "Name": "The Overloaders",
    "Genre": "Reverb-Fronted Surf Music",
    "City": "Bamberg",
    "Country": "DE",
    "Info": "Den ersten Rodeo Tag versüßen uns als Sideshow-Band The Overloaders aus Bamberg. Grandiose Surf-Music in klassischer Form. Auf das Stilmittel Gesang wird bewusst verzichtet. Hierfür lassen die Arrangements auch keinen Platz mehr. Spannend!"
  },
  {
    "Year": "2024",
    "Day": "Freitag",
    "Date": "19.07.2024",
    "Time": "19:50 Uhr",
    "Name": "The Sensitives",
    "Genre": "Punk'n'Roll/Ska",
    "City": "Falun",
    "Country": "SE",
    "Info": "Das wir am Outside Rodeo Schweden können, wisst ihr ja mittlerweile. Jetzt legen wir mit den großartigen The Sensitives nach. Sie gehen gerade mit ihrem neuen Album \"Patch It Up And Go!\", auf dem Label \"Kidnapp Music\" unserer Buddies \"Pascow\", voll durch die Decke. Macht euch bereit für eine Mischung aus Punk, Rock'n Roll, Ska und Folk aus dem Norden Schwedens."
  },
  {
    "Year": "2024",
    "Day": "Freitag",
    "Date": "19.07.2024",
    "Time": "21:30 Uhr",
    "Name": "Muff Potter",
    "Genre": "Punkrock",
    "City": "Rheine/Münster",
    "Country": "DE",
    "Info": "Endlich am Alten Güterbahnhof: Die deutsche Punk-Legende Muff Potter. Wer kennt sie nicht, ihre Bordsteinkantengeschichten? Das eine Hinwendung zum Indie-Rock und das Hochhalten der alten Punk-Ideale kein Widerspruch sein muss, beweisen sie schon eine Weile auf größeren Bühnen. Gemeinsam mit Muff Potter fliegen wir an diesem Abend bis zum Mond...mindestens."
  }
]